.home {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.posts {
	margin: auto;
	vertical-align: top;
	max-width: 100vw;
	max-height: 100vh;
	width: auto;
	height: auto;
	overflow: scroll;
}

.menu {
	align-self: stretch;
}

.evenly-spaced-row {
	display: flex;
	justify-content: space-evenly;
}

.evenly-spaced-row > * {
	flex: 1;
}

@media only screen and (max-width: 768px) {
	.evenly-spaced-row {
		display: flex;
		flex-direction: column;
	}
}
