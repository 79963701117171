.settings {
	padding: 1em;
}

.login {
	padding-bottom: 1em;
}

.options {
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
}
