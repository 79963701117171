.display {
	display: flex;
	padding: 0.75em;
	padding-left: 1em;
	background-color: rgb(176, 248, 158);
	word-break: break-all;
}

.user {
	padding: 0.5em;
	padding-left: 1em;
	background-color: rgb(224, 255, 227);
	word-break: break-all;
}

.user:hover {
	background-color: rgb(255, 255, 255);
}

.body {
	padding: 1em;
	background-color: rgb(255, 255, 255);
}

.tweetLink {
	margin-left: 0.75em;
	padding-left: 0.75em;
	border-left: solid rgb(172, 172, 172) 0.1em;
	height: 100%;
	color: rgb(172, 172, 172);
}
.tweetLink:hover {
	color: rgb(0, 0, 0);
}
.tweetLink:active {
	color: rgb(255, 255, 255);
}

.pageLink {
	font-size: 0.94em;
	background-color: transparent;
	margin-left: 0.75em;
	padding-left: 0.75em;
	border-left: solid rgb(172, 172, 172) 0.1em;
	color: rgb(172, 172, 172);
	text-decoration: underline;
}
.pageLink:hover {
	color: rgb(0, 0, 0);
}
.pageLink:active {
	transition: 0s;
	color: rgb(255, 255, 255);
}

a,
button {
	display: inline;
	vertical-align: middle;
}
