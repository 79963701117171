.post {
	vertical-align: top;
	max-width: 100vw;
	max-height: 75vh;
	width: auto;
	height: auto;
}

.hiddenPost {
	z-index: -1;
	position: absolute;
	opacity: 0;
}
